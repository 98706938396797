import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout/Layout";
import "../styles/base.scss";
import Form from "../components/Form/Form";
import { Helmet } from "react-helmet";
import { useChilipiper } from "../hooks/useChilipiper";

const bookDemo = ({ data, errors, location }) => {
    if (errors) return <Layout errors={errors} />;

    const { demoCta } = data.constants;
    const { socialProofData } = data.page.content;
    const href = location.href;

    const { apiResponse, showApiResponse, handleSubmit } = useChilipiper("public-demo-form");

    const {
        description: seoDescription,
        keywords: seoKeywords,
        noindex: seoNoIndex,
        title: seoTitle,
        data: seoData,
        altCanonical,
    } = data.page.content.seo;

    const seoPageData = {
        title: seoTitle ? seoTitle : page.title,
        description: seoDescription ? seoDescription : "",
        keywords: seoKeywords ? seoKeywords : null,
        noIndex: seoNoIndex ? seoNoIndex : false,
        data: seoData ? seoData : false,
        altCanonical: altCanonical,
        href: href,
    };
    const formParams = {
        formId: "chilipiperDemoForm",
        formClasses: "gtm_track_book_demo_form",
        el: ".js-signup-form-single", //can be used for GA tracking/custom GA events
        inputs: [
            "firstName",
            "lastName",
            "restaurantName",
            "locationCount",
            "email",
            "mobileNumber",
        ],
        formType: "bookDemo",
        leadType: "bookDemo",
        validation: {
            firstName: "required",
            lastName: "required",
            restaurantName: "required",
            locationCount: "required",
            mobileNumber: "required",
            email: "required",
        },
    };

    return (
        <Layout hideForm seoData={seoPageData}>
            <Helmet htmlAttributes={{ lang: "en" }} defer={false}>
                <script
                    src="https://js.chilipiper.com/marketing.js"
                    type="text/javascript"
                    async
                ></script>
            </Helmet>
            <Form
                formParams={formParams}
                headingText={[
                    "Get a ",
                    <span key="title-demo-span" className="accent">
                        demo
                    </span>,
                ]}
                ctaText={demoCta ? demoCta : "Book a demo"}
                // subHeadingText="We’ll reach out within 24 hours to schedule your demo. Lorem ipsum dolor set."
                apiResponse={apiResponse}
                handleSubmit={handleSubmit}
                showApiResponse={showApiResponse}
                showSocialProof
                socialProofData={socialProofData}
            />
        </Layout>
    );
};

export default bookDemo;

export const query = graphql`
    query DemoQuery {
        constants: sanitySiteSettings {
            id
            demoCta
        }
        page: sanityPage(pageTitle: { eq: "Book Demo" }) {
            content: signupContent {
                seo {
                    description
                    title
                    keywords
                    noindex
                    data
                    altCanonical
                }
                socialProofData: heroBanner {
                    headingText: marqueeText
                    socialProofImages: marqueeImages {
                        ...ImageWithPreview
                        asset {
                            metadata {
                                dimensions {
                                    width
                                    height
                                }
                            }
                            url
                            filename
                        }
                    }
                }
            }
        }
    }
`;
